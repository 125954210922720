<template>
  <v-card
    id="layer-selector"
    flat
    class="mx-auto"
  >
    <v-card-title>Layers</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="5">
          <v-card
            class="mx-4"
            width="235"
          >
            <v-toolbar
              flat
              dense
              dark
              color="color_green"
            >
              <v-toolbar-title>Select</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              />
              <v-spacer />
              <v-btn
                icon
                @click="leftCheckAll"
              >
                <v-icon>mdi-check-circle-outline</v-icon>
              </v-btn>
            </v-toolbar>
            <v-virtual-scroll
              :items="
                geoserver_data.geoserverLayers.filter(
                  el => !geoserver_data.chosenLayers.includes(el)
                )
              "
              height="220"
              item-height="50"
              bench="5"
            >
              <template v-slot="{ item }">
                <v-list-item
                  :key="item"
                  dense
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-chip
                        color="black"
                        disabled
                        label
                        outlined
                      >
                        {{ item }}
                      </v-chip>
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-checkbox
                      v-model="leftSelectedLayers"
                      color="color_green"
                      :value="item"
                    />
                  </v-list-item-action>
                </v-list-item>

                <v-divider />
              </template>
            </v-virtual-scroll>
          </v-card>
        </v-col>
        <v-col
          cols="1"
          align-self="center"
        >
          <v-btn-toggle
            borderless
            class="btn-toggle"
          >
            <v-btn
              :disabled="disableWSmodify"
              icon
              x-large
              @click="emitEventToStepper"
            >
              <v-icon>mdi-chevron-triple-up</v-icon>
            </v-btn>
            <v-btn
              icon
              x-large
              @click="goToChosen"
            >
              <v-icon>mdi-arrow-right-circle-outline</v-icon>
            </v-btn>
            <v-btn
              icon
              x-large
              @click="goToSelect"
            >
              <v-icon>mdi-arrow-left-circle-outline</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="6">
          <v-card
            class="mx-4"
            width="235"
          >
            <v-toolbar
              flat
              dense
              dark
              color="color_green"
            >
              <v-toolbar-title>Chosen</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              />
              <v-spacer />
              <v-btn
                icon
                @click="rightCheckAll"
              >
                <v-icon>mdi-check-circle-outline</v-icon>
              </v-btn>
            </v-toolbar>
            <v-virtual-scroll
              :items="geoserver_data.chosenLayers"
              height="220"
              item-height="50"
              bench="5"
            >
              <template v-slot="{ item }">
                <v-list-item
                  :key="item"
                  dense
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-chip
                        color="black"
                        disabled
                        label
                        outlined
                      >
                        {{ item }}
                      </v-chip>
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-checkbox
                      v-model="rightSelectedLayers"
                      color="color_green"
                      :value="item"
                    />
                  </v-list-item-action>
                </v-list-item>

                <v-divider />
              </template>
            </v-virtual-scroll>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      leftSelectedLayers: [],
      rightSelectedLayers: [],
      disableWSmodify: false
    };
  },

  computed: {
    ...mapState(['geoserver_data'])
  },

  created() {
    if (this.geoserver_data.isModifingWorkspace == true) {
      this.disableWSmodify = true;
    } else {
      this.disableWSmodify = false;
    }
  },

  methods: {
    ...mapActions(['deleteLayers', 'addLayers']),
    emitEventToStepper() {
      this.$emit('toStepper');
    },
    leftCheckAll() {
      if (
        this.leftSelectedLayers.length !=
        this.geoserver_data.geoserverLayers.length
      ) {
        this.leftSelectedLayers = [];
        this.geoserver_data.geoserverLayers.forEach(layer => {
          this.leftSelectedLayers.push(layer);
        });
      } else {
        this.leftSelectedLayers = [];
      }
    },
    rightCheckAll() {
      if (
        this.rightSelectedLayers.length !=
        this.geoserver_data.chosenLayers.length
      ) {
        this.rightSelectedLayers = [];
        this.geoserver_data.chosenLayers.forEach(layer => {
          this.rightSelectedLayers.push(layer);
        });
      } else {
        this.rightSelectedLayers = [];
      }
    },
    async goToChosen() {
      this.leftSelectedLayers.forEach(element => {
        this.geoserver_data.chosenLayers.push(element);
      });
      await this.deleteLayers(this.leftSelectedLayers);
      this.leftSelectedLayers = [];

      this.geoserver_data.layers = this.geoserver_data.chosenLayers;
    },
    async goToSelect() {
      await this.addLayers(this.rightSelectedLayers);
      this.rightSelectedLayers.forEach(element => {
        this.geoserver_data.chosenLayers.forEach(layer => {
          if (layer === element) {
            const index = this.geoserver_data.chosenLayers.indexOf(layer);
            this.geoserver_data.chosenLayers.splice(index, 1);
          }
        });
      });
      this.rightSelectedLayers = [];
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-toggle {
  flex-direction: column;
}
</style>
